
#navigation {
  background: rgba(#060E2C,0.85);
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  z-index: 3;
  .close {
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAdNJREFUeNrs3NFtwzAMBFChE2SUjqTNOlJH6QiqgTr5KFrY5h0tUjwC/AuE5AGOKEpya6FjfPykwoo39hQigCdEAp4QCXhCJOAJkYAnxAt4X3sK0Yj3vqcQbXivzwrRjidEAp4QCXjlERl4ZRGZeOUQPfDKIHriLY94B96yiHfiLYc4A28ZxJl46REj4KVFjISXDjEiXhrEyHjhEe14Y4zHlp9bdvhbbGPsYz0SIVLwntFBvGdkQaTimRF/4WVBdMG7jPgPXnREV7zTiAd4URGx2fYk3iHiSbwXYpDZGS9VLv7wPxEZY0xA5NV5CIAPnjsiv0i2QPjiuSH6rTAMIM54dET/5ZkTYif836OI961tyYidWHFYEe9vDJAQe6PHZcR5XRUQsTe3OI04vyVlROzNPY4R35oid2N0gUdYk0jKmXjBMkaFdIrVSIGlnJoJITsyBdtZaqiG6UoXb+lrU0nbmgX2hjtpRi95OsFcuyU92kFFhFcPSQ8Xze/iBKrzVkbUKdUKeBERdVK/Il4ERN1WEt4cRN3YFN4cRN1a15X/OYh6c4deezIHUW8vAhCFByAKD0AUHoooPBBReA6IwgMQhQcgCg9AFB6AKDwAMTTetwADAEXHtmbBV+1ZAAAAAElFTkSuQmCC) no-repeat 0 0 / 32px 32px;
    display: none;
    position: absolute;
    right: 20px;
    top: 20px;
    height: 32px;
    width: 32px;
//    z-index: 9;
  }
  nav { margin: 20px 40px 40px; }
  li { white-space: nowrap; }
}
.main-menu {
  li { margin-bottom: 12px; }
  a { font-size: 21px; }
  a.active, a.active-trail { color: blue; }
}
.secondary-menu,
.language-switcher-locale-url {
  padding: 0;
  a { font-size: 18px; }
  li { 
    margin-bottom: 16px;
    padding: 0; 
  }
}
.language-switcher-locale-url {
  .active { display: none; }
}



/* RESPONSIVE
/* ---------------------------------------------------- */


// 700+
@media 
  only screen and (min-height: 560px),
  only screen and (min-width: 700px) {

  #navigation nav { 
    li { margin-bottom: 20px; }
    a { font-size: 36px; }
    .language-switcher-locale-url a,
    .secondary-menu a { font-size: 21px; }
  }


}

// smartphones in portrait mode
@media only screen and (min-width: 480px) and (max-height: 559px) {

  #navigation nav {
    margin: 40px;
    li { float: left; }
    li { width: 58%; }
    li:nth-child(2n) { width: 40%; }
  }

}

// smartphones in portrait mode
@media only screen and (min-width: 700px) and (max-height: 559px) {

  #navigation nav {
    li, li:nth-child(2n) {
      width: 32%;
    }
  }

}



// 1260+
@media only screen and (min-width: 1260px) {


}