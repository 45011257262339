.news {
  position: absolute;
  bottom: 0;
  padding: 20px 0 0;
  width: 100%;
  .news-item:first-child p:first-child { margin: 0; }
  .news-item {
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAACVBMVEUAAAAAAP////9d2MkjAAAAAXRSTlMAQObYZgAAAOJJREFUeNqd1kEOwkAMQ1HK/Q+NxIK3YBH5e+XJ9LuCtsm8Dj3PKwkvofESOt8Tnp8qT5WnzDORZytvUXnLyitUXqnyipVXrryNytuqvM3K2x7491fxBgLiTxQQ/2QB8TELiC+agP1V/0ECFK+PDccw9+eOJzoazh1wtLwhAMQuATBmDAAKuDLmyfMPE4w9ApbJg6dt8uBpmjwCaJs8eNomD4ydGgKIn1oSxmJsihh2a8sgNk6efkEfjpHv471Pnn7E6YeskNAPipUHVB5SeVDmYUxMwM+qPFWe8FX4KnzVyX8AAdcGDWhxh+oAAAAASUVORK5CYII=) no-repeat 0 3px / 24px 24px;
    padding-left: 40px;
    margin: 0 0 20px 0;
    min-height: 28px;
  }
}

// 980+
@media only screen and (min-width: 980px) {

  .news {
    padding: 20px 0;
    .news-item {
      background-position: 0 0;
      background-size: 32px 32px;
      padding-left: 50px;
      min-height: 32px;
    }
  }
}