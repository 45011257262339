@import "normalize";

$kobalt-bg: #001383;
$black-bg: rgba(black,0.25);

@mixin capitals(){
  font-family: "alternate-gothic-no-2-d", "Arial Narrow", sans-serif;
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 1;
  text-transform: uppercase;
}

@mixin clearfix() {
  content:"";
  display:table;
  clear:both;
}


/* RESPONSIVE
/* ---------------------------------------------------- */

// -700
@media only screen and (max-width: 699px) {


}

// 700+
@media only screen and (min-width: 700px) {


}

// 980+
@media only screen and (min-width: 980px) {


}

// 1260+
@media only screen and (min-width: 1260px) {


}