.node-type-contact-page {
  h1, h2 { display: none; }
  header { margin-bottom: 100px; }
  .unit { 
    display: table; 
  }
  .logo-full {
    height: auto;
    margin: 20px 0 40px;
    width: 80px;
  }
  .column { 
    box-sizing: border-box;
    display: table-cell;
    padding: 40px 80px;
    vertical-align: middle;
  }
  .contact-group-wrapper { overflow: auto; }
  .contact-group {
    float: left;
    width: 50%;
  }
  .contact-group:nth-child(2n+1) {
    clear: left;
  }
}


/* RESPONSIVE
/* ---------------------------------------------------- */

// -700
@media only screen and (max-width: 699px) {

  .unit { width: 100%; }

}

// 700+
@media only screen and (min-width: 700px) {

.node-type-contact-page {
    .logo-full {
      float: left;
      margin-right: 80px;
      width: 100px;
    }
  }


}

// 980+
@media only screen and (min-width: 980px) {

  .node-type-contact-page {
    .logo-full {
      margin-right: 120px;
      width: 120px;
    }
  }
  

}

// 1260+
@media only screen and (min-width: 1260px) {

  .node-type-contact-page {
    .logo-full {
      margin-right: 180px;
      width: 140px;
    }
  }

}