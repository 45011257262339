.submenu {
  margin-bottom: 40px;
  li {
    display: inline-block;
    padding: 0.5em 1em;
    text-transform: lowercase;
  }
  li:first-child { padding-left: 0; }
  li.active, li.active-trail {
    border: 2px solid blue;
    padding: 0.5em;
    margin-right: 0.5em;
  }
  a { font-size: 1.3333em; }
}


/* RESPONSIVE
/* ---------------------------------------------------- */


// -700
@media only screen and (max-width: 699px) {

  .submenu {
    li {
      padding: 0.5em 0.3em;
    }
    li.active, li.active-trail {
      border: 2px solid blue;
      margin-right: 0.2em;
      padding: 0.5em;
    }
    a { font-size: 1em; }
  }

}

// -400
@media only screen and (max-width: 399px) {

  .submenu a { font-size: 0.875em; }

}
