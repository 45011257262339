header { position: relative; }
h1 {
  cursor: pointer;
  height: 63px;
  left: 20px;
  margin: 0;
  position: absolute;
  top: 30px;
  width: 42px;
  span { 
    display: block;
    text-indent: -9999px;
    width: 42px; 
  }
  .logo-k { 
    background: transparent url(../images/KW-logo-k.gif) no-repeat 0 0 / 42px 40px;
    height: 40px; 
    margin-bottom: 2px;
  }
  .logo-w {
    background: transparent url(../images/KW-logo-w.gif) no-repeat 0 0 / 42px 22px;
    height: 22px; 
  }
}
h2 { 
  font-size: 40px;
  padding: 63px 0 20px 60px;
}


/* RESPONSIVE
/* ---------------------------------------------------- */

// -700
@media only screen and (max-width: 699px) {



}

// 700+
@media only screen and (min-width: 700px) {

  h1 {
    height: 100px;
    left: 0;
    top: 40px;
    width: 67px;
    span { width: 67px; }
    .logo-k { 
      background-size: 67px 63px;
      height: 63px; 
    }
    .logo-w {
      background-size: 67px 35px;
      height: 35px; 
    }
  }
  h2 { 
    font-size: 72px;
    padding: 82px 0 56px 120px;
  }

}

// 980+
@media only screen and (min-width: 980px) {

  h1 {
    height: 125px;
    width: 84px;
    span {
      width: 84px;
    }
    .logo-k { 
      background-size: 84px 79px;
      height: 79px;
    }
    .logo-w { 
      background-size: 84px 44px;
      height: 44px; 
    }
  }
  h2 { 
    font-size: 90px;
    padding: 94px 0 70px 150px; 
  }

}

// 1260+
@media only screen and (min-width: 1260px) {

  h2 { padding: 94px 0 70px; }

}