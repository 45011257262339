body {
  line-height: 1;
  font-family: "news-gothic-std", sans-serif;
}

h1, h2, h3 { @include capitals(); }
h2, h3 { margin: 0; }
h3 { 
  font-size: 36px; 
  margin: 20px 0;
}
.sidebar h3,
h3.smaller { font-size: 32px; }
h3:first-child { margin-top: 0; }
h4 { 
  font-size: 18px;
  font-weight: bold; 
  line-height: 1.6667;
  margin: 20px 0;
}

.content p, .content ul, .content ol, .content blockquote,
.unit p, .unit ul, .unit ol, .unit blockquote, .unit-row {
  font-size: 16px;
  line-height: 1.6667;
}

a { 
  color: white; 
  text-decoration: none;
}
p a, .link a, .box a, .pager-load-more a, .item-list a {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAASUlEQVR42p3MsQ0AIAwDQY/A/ksxkpHcuKB4CFVEci/JS+MX651hgmNlN/KHYzs0wrgmH++R+xYWjPmAMUQAYwQwRwBzBDBHCB/JcXuB3FoFNAAAAABJRU5ErkJggg==) repeat-x 0 100% / 6px 4px; 
  display: inline-block;
//  padding-bottom: 6px;
}
.pager-load-more a {
  font-size: 18px;
  line-height: 1.6667;
}
a:hover, a:active, a:focus { outline: 0; }
a:not(.active):hover { 
  color: blue; 
  cursor: pointer;
}

nav li { list-style: none outside none; }
.menu {
  margin: 0;
  padding: 0;
}
.main-menu a { @include capitals(); }
.submenu a { 
  font-weight: bold;
  text-transform: lowercase; 
}
.full-body {
  p { text-align: justify; }
  > p:first-child:first-letter {
    @include capitals();
    color: blue;
    float: left;
    font-size: 3.75em;
    margin-bottom: -0.5em;
  }
}
article ul {
  padding: 0;
}
article li {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAN0lEQVR4AWPADv7PB2OCAKH4PxTPJ0ExQhNhxYQ1IRTj1YSpmLAmkjWQ5CRyPU04WCmKOJKTBgBMH55ZcEIQMQAAAABJRU5ErkJggg==) no-repeat 0 0.6667em / 0.3333em 0.3333em;
  list-style: none outside none;
  margin-left: 0;
  padding-left: 1em;
  p { margin-top: 0; }
}
.tags {
  font-size: 12px;
  line-height: 1.5em;
}
.tag {
  color: blue;
  font-weight: bold;
  height: 1.5em;
  text-transform: uppercase;
}
.unit.tags { font-size: 14px; }



// -700
@media only screen and (max-width: 699px) {

  h3 { font-size: 28px; }

}

// 700+
@media only screen and (min-width: 700px) {

  .content p, .content ul, .content ol, .content blockquote,
  .unit p, .unit ul, .unit ol, .unit blockquote, .unit-row {
    font-size: 18px;
    line-height: 1.6667;
  }

}

// 980+
@media only screen and (min-width: 980px) {


}

// 1260+
@media only screen and (min-width: 1260px) {

  h3 { font-size: 50px; }

}