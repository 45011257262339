/* EVENTS & PERFORMANCES
/* ---------------------------------------------------- */


.unit-row {
  .unit { position: relative; }
  .event-date {
    float: none;
    width: 100%;
  }
  .event-url {
    position: absolute;
    right: 20px;
    text-align: right;
    top: 0;
//    top: 0.75em;
    width: 50%;
  }
}


.agenda-item {
  overflow: auto;
  .unit > * { 
    display: block;
    float: left;
  }
}

.has-title {
  .event-loc {
    display: block;
  }
}
.event-mid-column {
  h3 { 
    display: inline-block; 
    margin: 0;
    font-size: 28px;
    line-height: 36px;
  }  
}


/* RESPONSIVE
/* ---------------------------------------------------- */

// -400
@media only screen and (max-width: 400px) {

  .agenda-item {
    .event-mid-column { width: 100%; }
  }

}

// -700
@media only screen and (max-width: 699px) {


}

// 700+
@media only screen and (min-width: 700px) {

  .has-title {
    .event-date { width: 100%; }
    .event-mid-column { width: 73%; }
    .event-url { width: 25%; }
  }

}


// 980+
@media only screen and (min-width: 980px) {

  .unit-row {
    .event-date {
      display: inline-block;
      width: 25%;
    }
    .event-url { 
      float: right; 
    }
  }

  .has-title {
    .event-date { 
      float: left;
      padding-top: 0.1667em;
      width: 30%; 
    }
    .event-mid-column { 
      float: left;
      width: 55%;
//      h3 { font-size: 27px; }
    }
    .event-url { 
      width: 12%;
    }
  }

}
/*
// 1260+
@media only screen and (min-width: 1260px) {

  .event-mid-column h3 {
    font-size: 36px;
  }
}
*/