/* LOAD-MORE PAGER
/* ---------------------------------------------------- */

@keyframes verticalflip {
  0% { background-size: 30px 30px; }
  50% { background-size: 30px 0px; }
  100% { background-size: 30px 30px; }
}
.item-list .pager-load-more {
  margin: 0 0 40px;
}
.pager-load-more a {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAABG0lEQVR4Ae3bSXXDUAwF0EIwlEIolEIohDIxhEIohEAJhJdBe8/23Ujn/I3n68QrPX2YylgLFMKmFkQDrEcDrEcDLEUDLEAD7HctgwbYKokGWI8GWI8GWI8GWI8GWI8GWI8GWI8GWI8GWI8GWI0GWIAGWI92WIB2WIB2WIB2WIB2WIAmWIAGWIA+HptkSPKX5HPisL3n70Afj72l6l4PveP8g9FnYjP50PvP34GeAO/ATjz07vP9r5zkJ9NVDz2Fna5/9h1PoMe16EXY2j8chSVohZXoL4Cl6AAsQAMsQDssQwssRwOsRwOsRwOsRwMsR/8CbDfTul3aDfGOPKBQS8eWOpjW0cMOl3Z8uAPiPQLQQx49xtODWn4U7wFJebzGdiiopAAAAABJRU5ErkJggg==) no-repeat 0 50% / 30px 30px;
  display: inline-block;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding-left: 40px;
  text-transform: lowercase;
}
.pager-load-more a:hover {
  animation: verticalflip 0.5s 1;
}


/* RESPONSIVE
/* ---------------------------------------------------- */

// -700
@media only screen and (max-width: 699px) {

}

// 700+
@media only screen and (min-width: 700px) {

  .item-list .pager-load-more { margin-bottom: 60px; }

}

// 980+
@media only screen and (min-width: 980px) {

  .item-list .pager-load-more { margin-bottom: 80px; }

}

// 1260+
@media only screen and (min-width: 1260px) {

  .item-list .pager-load-more { margin-bottom: 100px; }

}