.mejs-audio { 
  margin-bottom: 20px;
  width: 100% !important; 
}

/* RESPONSIVE
/* ---------------------------------------------------- */

// -700
@media only screen and (max-width: 699px) {


}

// 700+
@media only screen and (min-width: 700px) {

  .mejs-audio { margin-bottom: 30px; }

}

// 980+
@media only screen and (min-width: 980px) {


}

// 1260+
@media only screen and (min-width: 1260px) {

  .mejs-audio { margin-bottom: 50px; }

}