/* TEASERS
/* ---------------------------------------------------- */

.work.teaser,
.project.teaser {
  padding-top: 40px;
  padding-bottom: 40px;
  a:after { @include clearfix(); }
}
.work.teaser {
  .thumbnail { 
    display: block;
    float: left; 
    height: auto;
    margin: 0 40px 0 0;
    min-height: 168px;
    width: 300px;
    img { 
      display: block; 
      height: auto;
      width: 100%;
    }
  }
  a:hover {
    .thumbnail:not(:empty) { background: blue; }
    img { opacity: 0.4; }
  }
  .content { margin-left: 340px; }
  .field-performances {
    line-height: 32px;
    float: right;
    font-weight: bold;
    .perf-date { display: none; }
    .field-label + .perf-date { display: inline; }
  }
}
.project.teaser {
  .field-events {
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 10px;
  }
}

/* RESPONSIVE
/* ---------------------------------------------------- */

// -700
@media only screen and (max-width: 699px) {

  .work.teaser {
    padding-top: 20px;
    padding-bottom: 20px;
    .thumbnail { display: none; }
    .content { margin-left: 0; }
  }
  .monsoon-edition.teaser {
    overflow: auto;
    .monsoon-title { width: 100%; }
    .event-date, .event-loc { 
      float: left;
      width: 50%;
    }
  }


}

// 700+
@media only screen and (min-width: 400px) {

  .project.teaser {
    .field-events { float: left; }
    .tags { float: right; }
    h3 { clear: both; }
  }

}

// 700+
@media only screen and (min-width: 700px) {
  .project.teaser .field-events {  margin-bottom: 20px; }
/*
  .work.teaser .field-performances,
  .project.teaser .field-events { line-height: 40px; }
*/
}

// 980+
@media only screen and (min-width: 980px) {

}

// 1260+
@media only screen and (min-width: 1260px) {
/*
  .work.teaser .field-performances,
  .project.teaser .field-events { line-height: 64px; }
*/
}
