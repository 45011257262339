/* NEWSLETTER SUBSCRIPTION
/* ---------------------------------------------------- */

.campaignmonitor-subscribe-form {
  * { 
    font-size: 18px; 
  }
  label, .form-item-email { display: block; }
  label { width: 10rem; }
  #edit-customfields-firstnames,
  #edit-customfields-lastnames,
  #edit-email { 
    margin: 0 1em 0 0;
    width: 100%; 
  }
  .form-item-email .form-required { display: none; }
  input[type="submit"] {
    display: block;
  }
}


/* RESPONSIVE
/* ---------------------------------------------------- */

// -700
@media only screen and (max-width: 699px) {

}

// 700+
@media only screen and (min-width: 700px) {


}

// 980+
@media only screen and (min-width: 980px) {

  .campaignmonitor-subscribe-form {
    #edit-customfields-firstnames,
    #edit-customfields-lastnames,
    #edit-email { 
      margin: 0 1em 0 0;
      width: 25rem; 
    }
    input[type="submit"] {
      display: block;
      margin: 2em 0 2em 11rem;
    }    
  }

}

// 1260+
@media only screen and (min-width: 1260px) {


}