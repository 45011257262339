@import "base";
@import "forms";
@import "typography";


/* GENERAL LAYOUT
/* ---------------------------------------------------- */

body {
  background: $kobalt-bg;
  color: white;
}

/* DOWNLOADS
/* ---------------------------------------------------- */

.downloads {
   text-align: center;
  .download {
    display: inline-block;
    margin-bottom: 40px;
  }
  p {
    background: rgba(black,0.2);
    margin: 0;
    padding: 0.75em;
  }
  .file-meta {
    font-size: 0.7778em;
  }
  a {
    background: 0;
    font-weight: 700;
  }
}
.download:after {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAcCAQAAACesrS1AAAAy0lEQVR4AbXVsR0BQRBG8enhsov0IIYGFKAFqQYu1oE6roErQAoKuBSQAZgEzO/B5222uzv/cM0qVqVV5sdjmcfneXe32kzrTZ501gxI5y5FZJH5OZPOOYmckzHnZKdFHJDOBaRzd+1WTkYckD4wIOEUuM9IAfcp+TsH5G51P3w+kHAx2WvfkxKOSQGnIEd94LRkkQGnI5mTksxJSebkpJ7jn4Q5PcmcntRzTso5JtUcl4Zcan8qecklOoBJPQekmgNSzQGp5oD8kjsBkbP0GvYUuUUAAAAASUVORK5CYII=) no-repeat 50% 0 / 29px 14px;
  content: "↓";
  display: block;
  height: 14px;
  text-indent: -9999px;
}

/* WORKS
/* ---------------------------------------------------- */

.created {
  margin-bottom: 2em;
  p {
    background: rgba(black,0.2);
    margin: 0;
    padding: 0.75em;
    text-align: center;
  }
}

/* PROJECTS
/* ---------------------------------------------------- */

.tag {
  border: 1px solid blue;
  display: inline-block;
  margin: 0 0.5em 0.5em 0;
  padding: 2px 0.25em 0;
}
.unit.tags {
  margin-bottom: 30px;
  .tag { padding-top: 1px; }
}


/* MONSOON
/* ---------------------------------------------------- */

.monsoon-title {
  display: inline-block;
  @include capitals();
  font-size: 24px;
  width: 25%;
}


/* PEOPLE
/* ---------------------------------------------------- */

.person .placeholder {
  background: black;
  height: 100%;
  min-width: 150px;
}


/* EMBEDDED IMAGES
/* ---------------------------------------------------- */

@media only screen and (max-width: 699px) {


.media-embedded img,
.media-embedded-half img { width: 100%; }


}


/* BORDER-BOX
/* ---------------------------------------------------- */

.border-box {
  border: 2px solid blue;
  background: rgba(black,0.2);
  padding: 1em;
}
.sidebar .border-box {
  padding: 0.75em;
}

/* INCLUDES & OVERRIDES
/* ---------------------------------------------------- */

@import "background";
@import "grid";
@import "header";
@import "menu";
@import "menu-links";
@import "submenu";
@import "search";
@import "news";
@import "trailers";
@import "audio";
@import "gallery";
@import "teaser";
@import "events";
@import "load-more";
@import "newsletter";
@import "contact";
@import "user";
@import "admin";

.hidden {
  position: absolute;
  top: -9999px;
}
.reader-only { 
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
.clear {
  clear: both;
}