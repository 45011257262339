#page {
  min-height: 100vh;
}
.unit { 
  box-sizing: border-box;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.unit:after{ @include clearfix(); }
.unit-wrapper {
  background: $black-bg;
  border-top: 3px solid rgba(white,0.05);
  border-bottom: 3px solid rgba(white,0.05);
  display: block;
}
.unit-wrapper + .unit-wrapper {
  border-top: 0;
}
.section { margin-bottom: 40px; }

.full-body {
  img {
    display: block;
    height: auto;
    margin: 40px auto;
  }
  .media-embedded img { width: 100%; }
  .media-embedded-half img { width: 50%; }
}
.unit-row {
  padding: 0.75em 0;
}

.sidebar .box {
  background: rgba(black,0.2);
  margin-bottom: 100px;
  padding: 1px 20px;
}


/* RESPONSIVE
/* ---------------------------------------------------- */

// -700
@media only screen and (max-width: 699px) {

  .unit { padding: 0 20px; }

}

// 700+
@media only screen and (min-width: 700px) {

  .unit { width: 640px; }
  .section { margin-bottom: 60px; }

}

// 980+
@media only screen and (min-width: 980px) {

  .unit { 
    clear: both;
    position: relative;
    width: 940px; 
  }
  .section { margin-bottom: 80px; }
  .creatives-wrapper .unit {
    padding: 1em 0;
    column-gap: 40px;
    column-count: 2;
    & > ul { margin-top: 0; }
  }

  .full-body { 
    float: left;
    width: 600px;
  }
  .no-sidebar .full-body {
    float: none;
//    margin-left: auto;
//    margin-right: auto;
  }
  .sidebar {
    margin-top: 1em; // accomodate for margin on paragraphs in full-body
    float: right;
    width: 260px;
    .download { width: 100%; }
    .file-text { 
      display: inline-block; 
    }
  }
  .project.full .tags > *:first-child,
  .node-type-page .submenu > *:first-child {
    margin-left: 150px;
  }
  .no-sidebar .full-body,
  .indent-left {
    padding-left: 150px;
    width: 600px;
  }
}

// 1260+
@media only screen and (min-width: 1260px) {

  .unit { 
    box-sizing: content-box;
    padding: 0 140px; // room for the logo;
    position: relative;
    width: 980px;
  }
  .section { margin-bottom: 100px; }
  .creatives-wrapper .unit {
    padding: 1em 0;
    column-gap: 40px;
    column-count: 2;
    & > ul { margin-top: 0; }
  }
  .full-body { width: 640px; }
  .no-sidebar .full-body { width: 800px; }
  .sidebar {
    margin-top: 1em; // accomodate for margin on paragraphs in full-body
    float: right;
    width: 260px;
    .download { width: 100%; }
    .file-text { 
      display: inline-block; 
    }
  }
  .project.full .tags > *:first-child,
  .node-type-page .submenu > *:first-child {
    margin-left: 0;
  }
  .no-sidebar .full-body,
  .indent-left {
    padding-left: 0;
  }


}