.video-wrapper img {
  box-sizing: border-box;
  height: auto;
  width: 100%;
}
.vjs-poster { 
  background-size: cover;
  width: 100%; 
}

.trailer { margin-top: 20px; }
.trailer-first { margin-top: 0 !important; }
.trailer {
  .mejs-layer {
    max-height: 100%;
    max-width: 100%;
  }
}
.vimeo-trailer {
  .mejs-controls, .mejs-layers { display: none; }
  .me-plugin {
    height: 100%;
    width: 100%;
  }
  iframe {
    max-height: 100%;
    max-width: 100%;
  }
}


/* RESPONSIVE
/* ---------------------------------------------------- */

// -700
@media only screen and (max-width: 699px) {

  .trailer { 
    margin-top: 30px; 
    height: calc((100vw - 40px) / 16 * 9) !important;
    width: 100% !important;
  }

}

// 700+
@media only screen and (min-width: 700px) {

  .trailer { 
    margin-top: 30px;
    height: 360px !important;
    width: 640px !important; 
  }

}

// 980+
@media only screen and (min-width: 980px) {

  .trailer { 
    margin-top: 50px;
    height: 529px !important;
    width: 940px !important; 
  }

}

// 1260+
@media only screen and (min-width: 1260px) {


}