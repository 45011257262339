.menu-link {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAABZklEQVR4Ae3dB43DUBQF0YUQKAtpIQRCmHxIhrIQUj+Cp8yW0Tzpqln1uMf2zcffnut6pZniXXdCnOOFOMebI4YHIIY3RAxvjhje906IQ7zPnRlieHvmiOG9ATG8AWJ4IQJ4I8Tw5ojhhTjHC3GAF+IAL0QQ73q9nu457vniEb14e3hEMx6P6MfjEf14DsQB3gBQgAjihTjGCxE45p39iPwJY3kRebyrH5HHMyPyeFpE4Drvct3jR+Su85YAEcALkTnm+RH5E8byI/JnWzsiimdH5PHkiG8FPKYAP4x4/Mmt8LFm5wA/g7iXnfjjoBORwvMj8nhuRB5PjsjjmRFRvBABPAHimcfzIvInDDEijydG5PHEiDyeGPHC48kRUbwQ53ghEngCRP6EIUa04rGIPJ4f0Y9HIRrx+tiwz139iOH1yX+lE9WehBhe1U+Vj1V/F+LP41UBGmJ41SBXxF0VfIjh+f8O4wYRR9abcP7++AAAAABJRU5ErkJggg==) no-repeat 50% 50% / 32px 32px;
  display: block;
  position: absolute;
  height: 32px;
  overflow: visible;
  right: 30px;
  text-indent: -9999px;
  top: 40px;
  width: 32px;
  &:hover { animation: flip 0.5s 1; }
}
@keyframes flip {
  0% { background-size: 32px 32px; }
  50% { background-size: 0 20px; }
  100% { background-size: 32px 32px; }
}
#exit-link {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAACVBMVEUAAAD/BFT////W9lorAAAAAXRSTlMAQObYZgAAAOJJREFUeNrt2CFuA0AMBdH43//QBQVVMlUVaQYUZNiSpwUm9uPTr13t3cVeK953odeK91PkteI9F3tePBZ5j0J8QrRIwoL8kgTvAGgPgPYAvDw347Ftq72t9rba22pvkx7BWY+i9Shaj6L1KFqPovUoWo+i9Shaj6L2KL7v7b3gWXDwLDh4FlwPQrQgRAfGYzM/2PAgSo+i9SgKz4n0nEjPifScSM+J9JxIz4n0tAhPi/C8SM+L9LwYLz5rVzO/PLbiP1rAKYLXIrzwzOKiZys9iPC02B8kW7E/6rZifhj/9EdfVWgI20IAstIAAAAASUVORK5CYII=);
  right: 68px;
  &:hover:before {
    content: "Log out";
    color: white;
    display: block;
    line-height: 32px;
    position: absolute;
    right: 0;
    width: 6em;
    text-indent: 0;
  }
}

/* BACKLINK
/* ---------------------------------------------------- */

.back-link {
  text-align: right;
  a {
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAA5ElEQVR42u3b0QnCQBBFUUuxBEuxhHSWEi1hJBrwS9Cvo+QOTAGHkGR35+3J1KyPBqWws/d6GCxAOyxAOyxAOyxAO6xFe+xta4Bm2MvWAI2wewG0wwK0wwK0wwK0wwK0w0K0x87MGaAZdplnLQDNsAPQDgvQDqvRHvuqK0Az7G2GLE48FqAdFqAdFqAdFqAdFqAdFqAdVqAZVqIJuKfce9yXun/xp9iWlm0eMLo98ZfYjng6xOuYtoP4o49afmOY1ri0gXiRh0ItxZYKphU9LFz6Fl1mulT8rF3y6BpPF7X+B62wd7eIIci7QcVFAAAAAElFTkSuQmCC) no-repeat 0 50% / 30px 30px;
    display: block;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    padding-left: 40px;
    text-transform: lowercase;
  }
  a:hover {
    animation: doubleflip 0.5s 1;
  }
}
@keyframes doubleflip {
  0% { 
    background-position: 0 50%;
    background-size: 30px 30px;
  }
  25% { 
    background-position: 15px 50%; 
    background-size: 0 30px;
  }
  50% { 
    background-position: 0 50%;
    background-size: -30px 30px; 
  }
  75% { 
    background-position: 15px 50%; 
    background-size: 0 30px;
  }
  100% { 
    background-position: 0 50%;
    background-size: 30px 30px;
  }
}



// -700
@media only screen and (max-width: 699px) {

  .menu-link {
    right: 20px;
    top: 10px;
  }
  #exit-link { right: 56px; }
  .back-link { 
    margin: 0px 0 20px;
    position: static;
    text-align: left;
  }

}

// 700+
@media only screen and (min-width: 700px) {

  #menu-link { right: 0; }
  #exit-link { right: 40px; }
  .back-link { 
    margin: -30px 0 30px;
    position: static;
    text-align: left;
  }

}

// 980+
@media only screen and (min-width: 980px) {

  .back-link { 
    position: absolute;
    margin-top: -112px;
    right: 0;
  }
  #menu-link,
  #exit-link,
  #navigation .close { 
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
  #exit-link {
    right: 50px;
    &:hover:before {
      line-height: 40px;
      right: 5px;
    }
  }
  @keyframes flip {
    0% { background-size: 40px 40px; }
    50% { background-size: 0 40px; }
    100% { background-size: 40px 40px; }
  }


}