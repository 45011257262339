/* BACKGROUND
/* ---------------------------------------------------- */

.videojs-background-wrap {
  background-attachment: fixed;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.vjs-kobalt {
  background-color: transparent;
  color: #cccccc;
  .vjs-control-bar { display: none; }
}



#bgimage {
  position: fixed;
  top: 0;
  z-index: -2;  
}
#bgvideo.poster,
#bgvideo_html5_api.paused {
  background-blend-mode: overlay;
  left: 0;
  opacity: 0.4;
}
#bgimage {
  background-blend-mode: overlay;
  left: 0;
  opacity: 0.2;
}