/* SEARCH BLOCK FORM
/* ---------------------------------------------------- */

#search-block-form {
  box-sizing: border-box;
  margin: 20px 0;
  padding: 0 40px;
  width: 100%;
  * {
    line-height: 1;
  }
  &> div {
    position: relative;
  }
  .form-item {
    display: block;
    margin: 0;
    margin-right: 40px;
  }
  .form-actions {
    display: block;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
  input[type="text"] {
    background: transparent;
    box-sizing: border-box;
    border: 2.5px solid blue;
    border-radius: 0;
    height: 34px;
//    max-width: 400px;
    width: 100%;
  }
  input[type="text"]:focus {
    outline: 0;
    background: white;
  }
  input[type="submit"] {
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAYAAAA4E5OyAAABXklEQVR4Ae3aB43DQBRFUUMwBEMYCIYSCMNkIASCIRiCIRjCQLirnrapky89x+9KXz3J7nEvXdfBaTwGMYhBDBIAspcwyCmDGMQgBjGIQQxiEIMYBBiADMxA5bYKzEAGhp8FARIw8X4TkH4KBCi0VzYPAvTAwvdagF4AJAAjAEUdZCGuRQ+kfZ+xAgUYr6YAK88rmwABEo+rQH7+PWSg8ri0BZDpCUZ6E/cRyiQNAgztGG+jDMogmfvdbibR3ysAMvN/a9fYgx3trAxS+b8SeLZblUHuNX4BZOROkiBAvyMQg3iT8U7Vh12fmPnU3Rd3vvw/+AbRLW7yLcQQlHiQXh8lHkQeRf9BVQDKHh5lHoAagLLdh91AikWJBgkoFkUfJB5FGEQARRgkAKXIgAigHGU2GQGUE4Y+SDTKCSMeRB/l6NcyTyjF76k2JAliEIMYxCAGMYhBPAYwiEEM0jB/DmoCiXp9rpcAAAAASUVORK5CYII=) no-repeat 50% 50% / 34px 34px;
    border-radius: 0;
    cursor: pointer;
    height: 34px;
    overflow: hidden;
    margin: 0;
    text-indent: -9999px;
    width: 34px;
  }
  input[type="submit"]:hover {
    background-color: blue;
  }
}

/* SEARCH FORM
/* ---------------------------------------------------- */

#search-form {
  input[type="text"] { width: 100%; }
  input[type="submit"] { margin-bottom: 40px; }
}

/* SEARCH RESULTS
/* ---------------------------------------------------- */

.search-results {
  counter-reset: li;
  padding: 0 20px;
  li {
    list-style: none;
    margin: 40px 0;
    &> h3 { display: inline; }
  }
  li:before {
    color: blue;
    content: counter(li);
    counter-increment: li;
    @include capitals();
    font-size: 28px;
  }
}

/* RESPONSIVE
/* ---------------------------------------------------- */

// -700
@media only screen and (max-height: 599px) {

  #search-block-form {
    padding: 0 80px 0 40px;
    input[type="text"] {
      height: 32px;
    }
    input[type="submit"] {
      background-size: 32px 32px;
      height: 32px;
      width: 32px;
    }
  }

}

@media only screen and (min-height: 600px) {

  #search-block-form { 
    margin: 100px 0 40px;
    .form-item {
      margin-right: 50px;
    }
  }

}

@media only screen and (min-height: 600px),
       only screen and (min-width: 980px) {
  
  #search-block-form { 
    input[type="text"] {
      height: 40px;
    }
    input[type="submit"] {
      background-size: 40px 40px;
      height: 40px;
      width: 40px;
    }
  }

}

@media only screen and (max-height: 599px) and (min-width: 980px) {
  #search-block-form {
    .form-item {
      margin-right: 50px;
    }
  }
}

// 700+
@media only screen and (min-width: 700px) {


  #search-form {
    label { margin-right: 0; }
    .form-type-textfield {
      box-sizing: border-box;
      float: left;
      padding-right: 6em;
      margin-right: -6em;
      margin-bottom: 40px;
      width: 100%;
    }
    input[type="submit"] { 
      float: right; 
      margin-top: 3.5em;
    }
  }
  .search-results {
    li {
      padding: 0 20px;
      position: relative;
    }
    li:before {
      font-size: 36px;
      position: absolute;
      left: -40px;
      top: 0;
    }

  }

}

// 980+
@media only screen and (min-width: 980px) {


}

// 1260+
@media only screen and (min-width: 1260px) {

  .search-results li:before { font-size: 50px; }

}
