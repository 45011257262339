.form-item, .form-actions { font-size: 18px; }
input, textarea { color: black; }
input[type="text"],
input[type="password"],
textarea {
  background: white;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  padding: 0 0.381em;
  &:focus {
    color: blue;
    outline: 2px outset blue;
  }
}
label, input[type="text"], input[type="password"], input[type="submit"], .button {
  line-height: 2;
}
.form-type-textfield,
.form-type-password,
.form-type-checkboxes {
  & > label {
    clear: left;
    float: left;
    margin: 0 1em 0.5em 0; 
  }
}
label:after { content: ": "; }
.form-type-checkbox label:after,
.form-type-radio label:after { content: ""; }
.form-checkboxes { float: left; }

.form-item .description {
  margin: 0.5em 0 1em;
}

input[type="submit"], .button {
  background: blue;
  border: 0;
  border-radius: 0;
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  margin: 1em 0;
  padding: 0 0.7519em;
  text-transform: lowercase;
}
input[type="submit"]:hover, .button:hover {
  background: rgba(blue,0.5);
  color: blue;
}