.gallery-wrapper {
  background: $black-bg;
  margin-left: -10px;
  overflow: hidden;
  padding: 10px 0;
  width: 100%; // fallback
  width: calc(100% + 10px);
  .row { 
    float: left; 
    margin: 0;
  }
  .brick {
    float: left;
    margin: 0 0 10px 10px;
    position: relative;
    width: auto;
    a { 
      display: block;
      height: 100%;
    }
    img {
      display: block;
      height: 100%;
      width: auto;
    }
  }
  .row .brick { height: 100%; }
  figcaption {
    box-sizing: border-box;
    background: black;
    background: rgba(black,0.7);
    background: linear-gradient(rgba(black,0.1), rgba(black,0.7));
    bottom: 0;
    color: white;
    font-size: 16px;
    height: 2.5em;
    padding: 0.75em 0.5em;
    position: absolute;
    width: 100%;
    white-space: nowrap;
  }
}
.gallery-wrapper:after {
  @include clearfix();
}

.people > * { 
  height: 200px; 
  margin-top: 10px;
}
.media-wrapper > * {
  height: 200px;
  margin-top: 10px;
}


/* RESPONSIVE
/* ---------------------------------------------------- */


// -400
@media only screen and (max-width: 399px) {

  .media-wrapper {
    .file, img {
      height: auto;
      width: 100%;
    }
  }

}

// 700+
// @media only screen and (min-width: 700px) {

//}

// 980+
@media only screen and (min-width: 980px) {

  .media-wrapper > * { height: 220px; }

}

// 1260+
@media only screen and (min-width: 1260px) {

  .media-wrapper > * { height: 280px; }

}






