/* LOGIN FORM
/* ---------------------------------------------------- */

#user-login,
#user-login-form {
  label { 
    margin-right: 0;
    width: 100%; 
  }
  input[type="text"],
  input[type="password"] {
    width: 100%;
  }
  .item-list li {
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAACVBMVEUAAAAAAP////9d2MkjAAAAAXRSTlMAQObYZgAAAKVJREFUeNqd1jEKgDAQAEGT/z9aK68IZGC3Es8pQsT4XFvrya2vbqFhq15/xU7FTsnORbHQsNCw0LDQsOctW2tZaz80A1uMaD30eO8NfbPQsNCyp7YdfGrbwaeGhYaFhoWGhYaFhoUGxnZTt0XbSreNtpVuL7ctNGz/GFjDQsNCw0rbtkX1g87aNhzu1LLQsNCw0LDQsNCw4ZfZwSJYBItgESyCfQFfEQStcDG5IAAAAABJRU5ErkJggg==) no-repeat 0 50% / 30px 30px;
    font-weight: bold;
    margin-left: 0;
    min-height: 30px;
    list-style: none outside none;
    padding-left: 40px;
  }
}
.block-logintoboggan {
  text-align: center;
  .button  { margin: 0 1em; }
}

// 980+
@media only screen and (min-width: 980px) {

  #user-login,
  #user-login-form {
    label { 
      margin-right: 0;
      width: 13rem; 
    }
    input[type="text"],
    input[type="password"] {
      max-width: 27rem;
      width: calc(100% - 13rem);
    }

    .description,
    .item-list {
      margin: 0.5em 0 1em 13rem;
    }
    input[type="submit"] {
      margin: 1em 0 1em 13rem;
    }
  }

}

